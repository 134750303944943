import Header from "@/components/commonlayout/Header";
import { useAuth } from "@/hooks/auth";
import { userRole } from "@/utilities";
import Link from "next/link";
import React from "react";
import MainLayout from "@/components/layouts/MainLayout";
import Image from "next/image";

const ServerError = ({ statusCode }) => {
    const { user } = useAuth({ middleware: "auth" });
    const { userIsCompany, userIsEngineer } = userRole(user);

    let error;

    switch (statusCode) {
        case "404":
            error = (
                <Image
                    src={"/404.png"}
                    alt={"404"}
                    className="w-2/5"
                    width={450}
                    height={350}
                    loading="lazy"
                />
            );
            break;

        case "500":
        default:
            error = (
                <Image
                    src={"/500.png"}
                    alt={"500"}
                    className="w-2/5"
                    width={450}
                    height={350}
                    loading="lazy"
                />
            );
            break;
    }

    return (
        <>
            <Header />
            <MainLayout headers={[statusCode]}>
                <div className="flex min-h-screen flex-col bg-white pt-32 pb-12">
                    <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
                        <div className="flex flex-shrink-0 justify-center">
                            {error}
                        </div>
                        <div className="text-center">
                            <Link
                                href={
                                    userIsCompany
                                        ? "/company/dashboard"
                                        : userIsEngineer
                                        ? "/engineer/profile"
                                        : "/"
                                }
                            >
                                <a
                                    className="relative inline-flex items-center px-4 py-2 mr-3 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm mt-6"
                                    data-test-id="back"
                                >
                                    <span className="sr-only">Home</span>
                                    Home
                                </a>
                            </Link>
                        </div>
                    </main>
                </div>
            </MainLayout>
        </>
    );
};

export default ServerError;
